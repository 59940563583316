<template>
    <!-- <navigation /> -->
    <modal-confrim-print-receipt v-if="showPrintModal" @confirmed="handleConfirmPrint()" @canceled="handleCancelPrint()"/>

    <div v-cloak v-show="payment.async" class="mobile-full-screen-wrapper">
        <!-- <div v-cloak> -->
        <div class="pt-20">
            <h2 class="text-4xl font-bold">
                <span v-if="payment.amount"><currency/>{{ vueNumberFormat(payment.amount) }}</span>
            </h2>
            <h3 class="text-xl">{{$t('translations.views.transaction.payment.title')}}</h3>
        </div>
        <div class="flex justify-center mt-5">

            <div v-if="payment.in_payment">
                <div class="overflow-hidden  rounded-sm divide-y divide-gray-200">
                    <div class="px-4 py-5 sm:p-6">
                        <loading-indicator />
                    </div>
                </div>



                <div class="mt-32 status-payment">
                    <span class="inline-flex rounded-sm  shadow-sm w-full">
                        <a @click="statusPayment()"
                           class="button button-large button-wide button-normal flex justify-center">
                            <svg v-if="loading" class="animate-spin mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            {{$t('translations.views.transaction.payment.status')}}
                        </a>
                    </span>
                </div>

                <div class="mt-5 cancel-payment">
                    <span class="inline-flex rounded-sm  shadow-sm w-full">
                        <a @click="cancelPayment()"
                           class="button button-wide button-large button-danger flex justify-center">
                            <svg v-if="loading" class="animate-spin mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            {{$t('translations.views.transaction.payment.cancel-payment')}}
                        </a>
                    </span>
                </div>
            </div>



            <div v-else-if="status == 'failed'">
                <div class="list-secondary">
                    <div class="px-4 py-5 sm:p-6">
                        <h2 class="text-3xl font-bold mb-5">{{$t('translations.views.transaction.payment.payment-failed')}}</h2>
                        <p class="text-xl">{{$t('translations.views.transaction.payment.retry-payment')}}</p>
                    </div>
                </div>

                <div class="flex justify-center mt-10">
                    <span class="inline-flex rounded-sm  shadow-sm w-full text-center">
                        <a @click="initPayment()" class="button button-danger button-large button-wide">
                            {{$t('translations.views.transaction.payment.try-again')}}
                        </a>
                    </span>
                </div>
                <div class="flex justify-center mt-5">
                    <span class="inline-flex rounded-sm  shadow-sm w-full">
                        <router-link :to="{ name: 'transaction' }"
                                     class="button button-normal button-large button-wide">
                                     {{$t('translations.views.transaction.payment.close')}}
                        </router-link>
                    </span>
                </div>
            </div>
            <div v-else-if="payment_status == 'unknown'" class="text-center col-4 offset-4">
                <div class="alert alert-warning p-5 mt-5">
                    <h6>{{$t('translations.views.transaction.payment.unkown-status')}}</h6>
                    <p>{{$t('translations.views.transaction.payment.inform-staff')}}</p>
                </div>
            </div>

        </div>

    </div>
</template>

<style scoped>
.cancel-payment, .status-payment {
    visibility: visible !important;
    /* animation: buttonFadeIn 15s linear !important; */
}

@keyframes buttonFadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
    98% {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
    100% {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
}
</style>

<script>
import axios from 'axios';
import Echo from "laravel-echo";
import PosHelper from "../../mixins/pos.helper";

// print
import DrawerOpen from '../../prints/draweropen';
import PrintReceipt from '../../prints/receipt';
import PrintReceiptEft from '../../prints/receipt-eft';


window.Pusher = require('pusher-js');

// components
import LoadingIndicator from '../../components/LoadingIndicator';
import ModalConfrimPrintReceipt from '../../components/ModalConfrimPrintReceipt.vue';

// modals

export default {
    name: 'Payment',
    mixins: [PosHelper],
    props: ['area_id', 'table_id'],
    components: {
        LoadingIndicator,
        ModalConfrimPrintReceipt,
    },
    data () {
        return {
            status: null,
            payment_status: null,
            loading: false,
            showPrintModal: false,
        };
    },

    mounted () {
        if (!this.payment.in_payment) {
            this.$store.dispatch('payment/setAsync', false);

            this.initPayment();
        } else {
            this.$store.dispatch('payment/setAsync', true);
            this.connect();

            if (this.$route.query.id) {
                this.statusPayment();
            }
        }
    },

    methods: {
        async initPayment () {
            // check for transaction and paymenttype
            if (!this.validate()) {
                this.$router.push({ name: 'transaction' });
                return;
            }

            // set transaction to in payment
            this.$store.dispatch('payment/setInPayment', true);

            // connect pusher
            this.connect();
            // start payment
            await axios.post('/transactions/' + this.transaction.id + '/payment', {
                paymenttype_id: this.payment.paymenttype_id,
                mutation_id: this.payment.mutation_id,
                amount: this.payment.amount,
            }).then(response => {
                // set payment_id
                this.$store.dispatch('payment/setId', response.data.payment.id);

                // pending
                if (response.data.action.result_code == 'pending') {
                    this.$store.dispatch('payment/setAsync', true);

                    this.handlePendingPayment();

                    return;
                } else if (response.data.action.result_code == 'pending_adyensync') {

                    this.$store.dispatch('payment/setAsync', true);

                    this.handlePendingPayment();

                    axios({
                        method: 'post',
                        url: '/transactions/' + this.transaction.id + '/payment/' + this.payment.id + '/start',
                        timeout: 125000, // Let's say you want to wait at least 2 mins
                        // timeout: 60 * 2 * 1000, // Let's say you want to wait at least 2 mins
                    })
                        .then(response => {

                            if (response.data.status === 'failed') {
                                this.handleFailedPayment();
                            } else if (response.data.status === 'paid') {
                                this.handleSuccessPayment(response.data.payment);
                            }

                        }).catch(error => {
                        console.error('error', error);
                        this.handleFailedPayment();
                    });
                }

                // redirect
                else if (response.data.action.result_code == 'redirect') {

                    window.location = response.data.action.redirect_url;
                    // this.$store.dispatch('payment/setAsync', true);

                    // this.handlePendingPayment()

                    return;
                }

                // confirmed
                else if (response.data.action.result_code == 'confirmed') {
                    this.handleSuccessPayment(response.data.payment);

                    return;
                }
            }).catch(error => {
                console.error('error', error);
                this.handleFailedPayment();
            });
        },

        validate () {
            if (!this.transaction.id) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Ongeldige actie",
                    text: "Geen transactie id aanwezig",
                }, 2000);
                return false;
            }
            if (!this.payment.paymenttype_id) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Ongeldige actie",
                    text: "Geen betaalmethode geselecteerd",
                }, 2000);
                return false;
            }
            // if (this.payment.amount !== null) {
            //     this.$notify({ group: "notifications", type: 'error', title: "Ongeldige actie", text: "Geen bedrag opgegeven" }, 2000);
            //     return false
            // }
            return true;
        },

        // Open connection
        connect () {

            // disconnect previos connection
            console.log('-----connection-----');

            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.VUE_APP_PUSHER_KEY,
                cluster: 'eu',
                encrypted: true,
                authEndpoint: process.env.VUE_APP_API_URL + 'broadcasting/auth',
                auth: { headers: { Authorization: "Bearer " + this.$store.getters['auth/token'] } },
            });

            window.Echo.private('paymentstatus.' + this.terminal.id).listen('\\App\\Domain\\Tenant\\Transaction\\Events\\TransactionPaymentStatusUpdated', event => {
                this.handleStatusChange(event.payment);
            });
        },

        // Close connection
        disconnect () {
            console.log('-----connection close------');
            window.Echo.disconnect();
        },

        handleStatusChange () {
            this.statusPayment();
            // if (this.payment.id == payment.id) {
            //     this.statusPayment()
            // }
        },


        // handleFailedPayment(message) {
        handleFailedPayment () {

            this.disconnect();

            this.$store.dispatch('payment/setAsync', true);
            this.$store.dispatch('payment/setInPayment', false);

            this.status = 'failed';

            return;
        },

        handlePendingPayment () {
            this.status = 'pending';
            return;
        },

        async handleSuccessPayment (payment) {

            this.disconnect();

            // print eft receipt
            if (this.terminal.receipt_print_eft && payment.attributes.eft_receipt_customer) {
                new PrintReceiptEft().print(payment.attributes.eft_receipt_customer);
            }

            // Drawer open
            if (payment.relationships.paymenttype.attributes.opendrawer) {
                new DrawerOpen().print();
            }

            // if (this.terminal.printer_mode.includes('local_S1F2')) {
            //     let environment = this.terminal.printer_mode.replace('local_S1F2_', '');
            //
            //     await this.printS1F2(environment, this.terminal.pin_terminal_id);
            // }

            // set transaction to in payment
            this.$store.dispatch('payment/setInPayment', false);

            // notify
            this.$notify({ group: "notifications", type: 'success', title: "Succes", text: "Betaling geslaagd" }, 1000);

            // get the payment status
            try {
                const response = await axios.get('/transactions/' + this.transaction.id + '/status');

                console.log('response', response);

                if (response.data.status == 'completed') {
                    // has cards?
                    if (response.data.cards.length > 0) {
                        this.handleCards(response.data.cards);
                    }

                    this.transaction.balancedue = response.data.balancedue;

                    this.$store.dispatch('terminal/setLastTransactionId', this.transaction.id);
                    this.$store.dispatch('terminal/setTransactionPrevious', this.transaction);
                    this.$store.dispatch('transaction/clear');

                    if (this.terminal.clerk_logout == 'direct') {
                        this.$store.dispatch('transaction/setClerk', null);
                    }

                    // exit admin mode
                    this.$store.dispatch('terminal/setAdminMode', false);

                    if (payment.relationships.paymenttype.attributes.printreceipt === true) {
                        let print = true;

                        if (payment.relationships.paymenttype.attributes.printer_mode === 'ask') {
                            print = await this.showPrintConfirmationModal();
                        }

                        if (print) {
                            axios.get('/transactions/' + payment.attributes.transaction_id)
                                .then(response => {
                                    if (this.terminal.printer_mode.includes('local_S1F2')) {
                                        this.printS1F2(response.data.data, 'transaction');
                                    } else {
                                        new PrintReceipt().print(response.data.data);
                                    }
                                })
                                .catch(error => {
                                    console.log('error', error.response);
                                });
                        }
                    }


                    this.$router.push({ name: 'landing' });
                } else {
                    this.$router.push({ name: 'transaction' });
                }
            } catch(error) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
                console.error('error', error);
            } finally {
                this.$store.dispatch('payment/clear');
            }
        },

        async handleCards (cards) {
            for (let card of cards) {
                axios.post('/cards/' + card.id + '/process').then(response => {
                    if (response.data.status != 0) {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Fout kaart",
                            text: response.data.message,
                        }, 2000);
                    } else {
                        this.$notify({
                            group: "notifications",
                            type: 'success',
                            title: "Kaart verwerkt",
                            text: 'De kaart is succesvol verwerkt',
                        }, 2000);
                    }
                }).catch(() => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                });
            }
        },

        handleConfirmPrint() {
            this.showPrintModal = false;
            this.confirmResolve(true);  // Resolves the promise with true
        },

        handleCancelPrint() {
            this.showPrintModal = false;
            this.confirmResolve(false); // Resolves the promise with false
        },

        async showPrintConfirmationModal() {
            this.showPrintModal = true;

            return new Promise((resolve) => {
                this.confirmResolve = resolve;
            });
        },


        async statusPayment (send_notification = true) {

            if (this.loading == true) {
                return;
            }
            if (!this.payment.id) {
                this.handleFailedPayment();

                return;
            }

            this.loading = true;

            let response = await this.checkStatusPayment();

            let status = response.status;
            let payment = response.payment;

            if (status == 'open' && send_notification === true) {
                this.$notify({
                    group: "notifications",
                    type: 'info',
                    title: "In afwachting",
                    text: "Betaling is in afwachting",
                }, 2000);
            } else if (status == 'failed') {
                this.handleFailedPayment();
            } else if (status == 'paid') {
                this.handleSuccessPayment(payment);
            }
            this.loading = false;

            return status;
        },

        async checkStatusPayment () {
            return await axios.get('/transactions/' + this.transaction.id + '/payment/' + this.payment.id + '/status').then(response => {
                return {
                    status: response.data.status,
                    payment: response.data.payment,
                };
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },

        async cancelPayment () {
            if (this.loading == true) {
                return;
            }

            let status = await this.statusPayment(false);

            if (status == 'open') {
                await axios.post('/transactions/' + this.transaction.id + '/payment/' + this.payment.id + '/cancel').finally(() => {
                    this.$notify({
                        group: "notifications",
                        type: 'warning',
                        title: "Geannuleerd",
                        text: "De betaling is geannuleerd",
                    }, 1000);
                    this.$store.dispatch('payment/clear');
                });

                this.$router.push({ name: 'transaction' });
            }
        },
    },
    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
        transaction () {
            return this.$store.getters['transaction/transaction'];
        },
        payment () {
            return this.$store.getters['payment/payment'];
        },
    },
};
</script>
